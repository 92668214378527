import { Helmet } from '@/components'
import { logEvent } from '@/utils/logEvent'
import { keyframes } from '@emotion/react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { graphql } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'
import { useRequest } from 'ahooks'
import { Footer, Header, SEO, useI18next } from '../components'
import { APIqueryCountry } from '@/components/EnquireForm/api'
import Swiper from '../components/Swiper'
import Title from '../components/Title'
import { useLanguageByIp } from '@/hooks/useLanguageByIp'
import WhatWeDoComponents from '../components/WhatWeDoComponents'
import Cookies from 'js-cookie'

const moveLtr = keyframes`
  from{
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`

const moveRtl = keyframes`
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(100%);
  }
`

export default (props) => {
  const {} = useTranslation()
  const theme = useTheme()
  const { direction } = theme
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const isLess600 = useMediaQuery('@media (min-width: 600px)')
  const { country } = useLanguageByIp()
  const callCodeRes = useRequest(APIqueryCountry)
  const callCodeList = callCodeRes?.data?.resultObject || []

  const solutionList = [
    {
      img: '/images/home/solution1.png',
      desc: pageLocale.solution1,
    },
    {
      img: '/images/home/solution2.png',
      desc: pageLocale.solution2,
    },
    {
      img: '/images/home/solution3.png',
      desc: pageLocale.solution3,
    },
    {
      img: '/images/home/solution4.png',
      desc: pageLocale.solution4,
    },
  ]

  useEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.homepage.title}
        description={seoLocale.homepage.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='last mile delivery, ecommerce shipping solutions, pickup and delivery service, ecommerce delivery service, last mile logistics solutions'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/6012dd844d0fcc97f9cd36f8393c6951/334d0/home2_bg.webp'
        />
        <meta property='og:title' content='Logistics and Last-mile Delivery Solutions' />
        <meta
          property='og:description'
          content='Reliable and efficient eCommerce delivery solutions. iMile uses smart logistics technology to offer unparalleled efficiencies throughout the supply chain.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Swiper
        pageLocale={pageLocale}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        callCodeList={callCodeList}
      />
      {/* </Box> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',

          width: 'var(--page-width)',
          overflow: 'hidden',
          listStyle: 'none',
          mt: ['20px'],

          '.imgBox': {
            animation: `${direction === 'rtl' ? moveRtl : moveLtr} 30s linear infinite`,
            paddingInlineEnd: ['30px', '60px'],
            img: {
              height: ['20px', '35px'],
              maxWidth: 'unset !important',
            },
          },
        }}
      >
        {[0, 1].map((a) => (
          <div key={a} className='imgBox'>
            <img
              src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/logo.png'
              alt='Our Last Mile Delivery Team'
            />
          </div>
        ))}
      </Box>
      <Title title={pageLocale.ECommerceSolutions} component='h1' />

      {/* Start ---- iMile delivers customized e-commerce solutions */}
      <Grid
        container
        columns={{ xs: 6, sm: 6, md: 3 }}
        sx={{
          display: 'flex',
          justifyContent: ['center', 'space-evenly'],
          flexWrap: 'wrap',
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
        }}
      >
        {solutionList.map((item, index) => {
          return (
            <Grid
              item
              columns={{ xs: 6, sm: 6, md: 3 }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                mb: [0, 0, 4],
                whiteSpace: 'pre',
                '.img': {
                  display: 'inline-block',
                  width: ['147px', 180, 210, 240],
                  height: ['123px', 158, 180, 200],
                  mx: [0, '40px', 0, 0, 0],
                  mb: ['30px', '35px', 0],
                },
              }}
              key={index}
            >
              <img src={item.img} alt='Our Last Mile Delivery Team' className='img' />
              <Box
                sx={{
                  position: 'absolute',
                  fontSize: ['14px', 16, 16, 18],
                  color: '#000',
                  fontWeight: '600',
                  maxWidth: ['159px', '200px'],
                  textAlign: 'center',
                  letterSpacing: [0, '1px'],
                  top: ['105px', '160px', '180px', '200px'],
                  mt: ['5px', 0],
                  whiteSpace: 'pre-line',
                }}
              >
                {item.desc}
              </Box>
            </Grid>
          )
        })}
      </Grid>

      {/* Start ----  What We Do */}
      <Title
        component='h4'
        title={pageLocale.WhatWeDo}
        maxWidth={['244px', '380px', '100%', '100%']}
        sx={{
          boxSizing: 'content-box',
          textAlign: 'center',
          fontSize: ['24px', '32px', '38px', 42, 44],
          margin: '30px auto 0',
        }}
      />
      <WhatWeDoComponents locales={pageLocale} isPc={isPc} country={country}/>
      {/* end ----  What We Do */}

      {/* Start ----  find out more */}
      <Box
        sx={{
          color: '#fff',
          bgcolor: '#095ef7',
          textAlign: 'center',
          fontSize: ['20px', '32px', '36px', 38, 40],
          fontWeight: 600,
          width: 'fit-content',
          height: ['45px', '66px'],
          lineHeight: ['41px', '66px'],
          borderRadius: ['56px'],
          px: '40px',
          m: ['40px auto', '50px auto'],
          ':hover': {
            cursor: 'pointer',
          },
          a: {
            textDecoration: 'none',
            color: '#fff',
          },
        }}
      >
        <Link to='/service/whatWeDo'> {pageLocale.findOutMore}</Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          '.img': {
            height: ['240px', '560px'],
            mx: isPc ? 'var(--page-px)' : 0,
          },
        }}
      >
        {/* 有没有其他方法可以在YouTube iframe播放器中指定poster？
        YouTube iframe 播放器会自动使用视频的缩略图作为海报，在用户点击播放按钮之前显示。这个缩略图通常是视频上传者在上传视频时自动生成的。

如果你想要在播放器上显示自定义的海报图像，你需要使用其他方法，例如自定义播放器或使用 YouTube API。

一种方法是使用 YouTube Player API，它允许你自定义播放器的外观和行为。你可以使用 API 设置自定义的海报图像，并在用户点击播放按钮时切换到实际的视频。

另一种方法是使用 YouTube JavaScript Player API，它提供了更多的控制权和功能。你可以创建一个自定义播放器，包括设置海报图像的功能，并在用户点击播放按钮时启动播放。

这两种方法都需要编写一些 JavaScript 代码，并使用 YouTube 的 API 密钥进行身份验证。你可以参考 YouTube 的开发者文档以获取更多详细信息和代码示例。
        */}

        <iframe
          className='img'
          src={
            country === 'CHN'
              ? 'https://v.qq.com/txp/iframe/player.html?vid=g352735jsoz'
              : 'https://www.youtube.com/embed/XetuTq6dLp4?origin=https://imile-minio-cdn.imile.com/imile-home/offical-web/home/officialconveyerbelt.png&enablejsapi=1'
          }
          title='imile delivery'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen
        ></iframe>

        {/* <img
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/Findoutmore.jpeg'
          alt='Our Last Mile Delivery Team'
          className='img'
        /> */}
        <Box
          sx={{
            fontSize: ['14px', '18px', '23px'],
            color: '#9b9b9b',
            fontWeight: 400,
            m: ['17px 0 0', '30px auto 0'],
            px: 'var(--page-px)',
          }}
        >
          {pageLocale.DependableDeliveryDesc}
          <Box
            component='span'
            sx={{
              fontSize: ['17px', '27px'],
              color: '#787878',
              fontWeight: 700,
            }}
          >
            {pageLocale.DeliveryTrust}
          </Box>
        </Box>
      </Box>
      {/* end ----  find out more */}

      {/* start ----  emergin markets */}

      <Title
        component='h4'
        title={pageLocale.EmergingMarkets}
        maxWidth={['249px', '380px', '100%', '100%']}
        sx={{
          boxSizing: 'content-box',
          textAlign: 'center',
          fontSize: ['24px', '32px', '38px', '44px'],
          px: 'var(--page-px)',
          margin: '0 auto',
        }}
      />
      <Box
        sx={{
          display: 'block',
          width: ['100vw'],
          position: 'relative',
          margin: ['0 auto 55px', '0 auto 148px'],
          img: {
            display: 'block',
            width: ['100vw'],
          },
        }}
      >
        <img
          alt='International Delivery Map'
          src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/home/map_new.gif'}
          className='car-img'
        />
      </Box>
      {/* end ----  emergin markets */}

      {/* APP 区域 */}

      {Cookies.get('COUNTRY') !== 'Italy' && (
        <Box
          sx={{
            background: '#fff303',
            width: '100vw',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: ['230px', 230, 380, 395, 501],
              // maxHeight: ['300px', '500px', '500px'],
              width: 'var(--page-width)',
              margin: '0 auto',
              '.img': {
                zIndex: 1,
                transform: direction === 'rtl' ? ' scaleX(-1)' : '',
              },
              '.mobile': {
                marginInlineStart: 'auto',
                width: 230,
              },
            }}
          >
            {isLess600 ? (
              <img
                alt='iMile ECommerce Delivery App'
                src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/home/web-phone.png'}
                className='img'
              />
            ) : (
              <img
                alt='iMile ECommerce Delivery App'
                src={
                  'https://imile-minio-cdn.imile.com/imile-home/offical-web/home/mobile-phone.png'
                }
                className='img mobile'
              />
            )}
            {/* {isLess600 ? (
            <StaticImage
              src='../../static/images/home/web-phone.png'
              alt='iMile ECommerce Delivery App'
              objectFit='cover'
              loading='lazy'
              placeholder='blurred'
              className='img'
            />
          ) : (
            <StaticImage
              src='../../static/images/home/mobile-phone.png'
              alt='iMile ECommerce Delivery App'
              objectFit='cover'
              loading='lazy'
              placeholder='blurred'
              className='img mobile'
            />
          )} */}
            {/* 应用商店 */}
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                py: ['10px', '40px', '50px', '50px', '93px'],
                px: ['16px', '40px', '40px', '40px', '80px'],
                maxWidth: '100vw',
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  fontSize: ['16px', 16, '30px', '32px', '32px'],
                  color: '#000',
                  letterSpacing: '1px',
                  fontWeight: 600,
                  whiteSpace: ['pre-line', 'normal'],
                  width: '100vw',
                }}
              >
                {isPc ? (
                  <span>{pageLocale.DownloadAPP}</span>
                ) : (
                  <Box sx={{ position: 'absolute' }}>{pageLocale.DownloadAPP_mobile}</Box>
                )}
              </Box>
              <Box
                sx={{
                  fontSize: ['12px', 18, 23, 27, 27],
                  color: '#000',
                  mt: ['10px'],
                  fontWeight: 400,
                }}
              >
                {isPc ? (
                  <>{pageLocale.DownloadAPPDesc}</>
                ) : (
                  <Box
                    sx={{
                      position: 'absolute',
                      mt: ['19px', '32px', '12px', '12px', '12px'],
                      maxWidth: [185, 238, 319],
                    }}
                  >
                    {pageLocale.DownloadAPPDesc}
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: ['142px', '50vw'],
                  mt: ['78px', '121px', '61px', '40px', '61px'],
                  '.img-app': {
                    width: ['121px', 120, 166, 166, 196],
                    height: ['34px', 36, 50, 50, 56],
                    cursor: 'pointer',
                    mb: ['8px'],
                    '&:not(:last-child)': {
                      marginInlineEnd: '10px',
                    },
                  },
                }}
              >
                <img
                  alt='Our Last Mile Delivery Team'
                  src={
                    'https://imile-minio-cdn.imile.com/imile-home/offical-web/footer/appstore.png'
                  }
                  className='img-app'
                  onClick={() => {
                    window.open('https://apps.apple.com/cn/app/imile-delivery/id1582766717')
                    logEvent('点击_首页_苹果下载')
                  }}
                />
                <img
                  alt='Our Last Mile Delivery Team'
                  src={
                    'https://imile-minio-cdn.imile.com/imile-home/offical-web/footer/googleplay.png'
                  }
                  className='img-app'
                  onClick={() => {
                    window.open('https://play.google.com/store/apps/details?id=com.imile.oasis')
                    logEvent('点击_首页_谷歌下载')
                  }}
                />
                <img
                  alt='Our Last Mile Delivery Team'
                  src={
                    'https://imile-minio-cdn.imile.com/imile-home/offical-web/footer/appgallery.png'
                  }
                  className='img-app'
                  onClick={() => {
                    window.open(
                      'https://appgallery.huawei.com/app/C104762729?sharePrepath=ag&locale=zh_CN&source=appshare&subsource=C104762729&shareTo=com.tencent.mm&shareFrom=appmarket',
                    )
                    logEvent('点击_首页_华为下载')
                  }}
                />
                {/* <StaticImage
                src='../../static/images/footer/appstore.png'
                alt='Our Last Mile Delivery Team'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='img-app'
                onClick={() => {
                  window.open('https://apps.apple.com/cn/app/imile-delivery/id1582766717')
                  logEvent('点击_首页_苹果下载')
                }}
              />
              <StaticImage
                src='../../static/images/footer/googleplay.png'
                alt='Our Last Mile Delivery Team'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='img-app'
                onClick={() => {
                  window.open('https://play.google.com/store/apps/details?id=com.imile.oasis')
                  logEvent('点击_首页_谷歌下载')
                }}
              />
              <StaticImage
                src='../../static/images/footer/appgallery.png'
                alt='Our Last Mile Delivery Team'
                objectFit='cover'
                loading='lazy'
                placeholder='blurred'
                className='img-app'
                onClick={() => {
                  // window.open('https://www.imile.com/deliveryapp/imile-delivery-android.apk')
                  window.open(
                    'https://appgallery.huawei.com/app/C104762729?sharePrepath=ag&locale=zh_CN&source=appshare&subsource=C104762729&shareTo=com.tencent.mm&shareFrom=appmarket',
                  )
                  logEvent('点击_首页_华为下载')
                }}
              /> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'block',
                '.img': {
                  width: ['92vw', '50vw', '60vw', '60vw', '53vw'],
                  maxWidth: '720px',
                  mt: ['1px', '-25px'],
                  marginInlineStart: ['-8px', 0],
                  zIndex: 1,
                },
              }}
            ></Box>
          </Box>
        </Box>
      )}

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
        notShowQuoteBtn={!isPc}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
