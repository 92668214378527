import { Box, useMediaQuery } from '@mui/material'
import { useRequest } from 'ahooks'
import { Button, Row, Select, message } from 'antd'
import React, { memo, useEffect, useState, useCallback } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useModalState } from '../../utils'
import { GlobalAntdFormStyle } from '../GlobalCSS/AntdFormStyle'
import { APIqueryBizDictData, APIqueryCountry, APIsubmitEnquire, APIgetServiceType } from './api'
import { Form, FormItem, FormItemProps } from './Form'
import { navigate } from 'gatsby'
import FormModal from './FormModal'
import SelectModalH5 from './SelectModalH5'
import { logEvent } from '@/utils/logEvent'
import PrivacyAgreement from '@/components/PrivacyAgreement'
import Cookies from 'js-cookie'
import { Checkbox } from 'antd'

const { Option } = Select

export const ptBoxInFormItemsx = {
  height: '46px',
  bgcolor: '#f6f8fb',
  borderRadius: '6px',
  px: '16px',
  lineHeight: '46px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

interface IEnquireForm {
  footerLocale: any
  locale: any
  btnTxt?: any
  btnSx?: any
  countryList: any[]
  dailyCountList: any[]
  callCodeList: any[]
}

const Index = ({
  footerLocale,
  locale,
  btnTxt,
  btnSx,
  countryList,
  dailyCountList,
  callCodeList,
}: IEnquireForm) => {
  const isPc = useMediaQuery('@media (min-width: 600px)')
  const { onOpen, onClose, visible } = useModalState()
  const selectModalState = useModalState()
  const [form] = Form.useForm()
  const [selectedCallCode, setSelectedCallCode] = useState<string>()
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedServiceType, setSelectedServiceType] = useState('')
  const [serviceTypeList, setServiceTypeList] = useState([])
  const [checkedFlag, setCheckedFlag] = useState<string[]>([])
  const { language } = useI18next()

  const { run } = useRequest(
    () => {
      return APIgetServiceType(selectedCountry)
    },
    {
      manual: true,
      onSuccess: (res) => {
        const list = res?.resultObject
        setServiceTypeList(list || [])
      },
    },
  )

  useEffect(() => {
    selectedCountry && run()
  }, [selectedCountry])

  useEffect(() => {
    if (Array.isArray(callCodeList)) {
      setSelectedCallCode(callCodeList?.[0]?.countryCallingCode)
    }
  }, [callCodeList])

  const submitRes = useRequest(APIsubmitEnquire, {
    manual: true,
    onSuccess: (res) => {
      console.log(1, res)
      if (res.status === 'success') {
        navigate('/enquiredSuccess')
      } else {
        message.error(res?.message)
      }
    },
  })

  const handleCheckboxChange = useCallback((e) => {
    setCheckedFlag(e)
  }, [])

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        submitRes.run({ ...values, interCallingCode: selectedCallCode })
        logEvent('点击_首页_咨询提交')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const selectBefore = (
    <Box
      component={Select}
      value={selectedCallCode}
      disabled={true}
      sx={{
        '.ant-select-selector': {
          border: 'none !important',
          bgcolor: '#f6f8fb !important',
        },
      }}
      suffixIcon={null}
      onSelect={(e) => setSelectedCallCode(e)}
    >
      {callCodeList?.map((i, k) => (
        <Option value={i.countryCallingCode} key={k}>
          {i.countryName} {i.countryCallingCode}
        </Option>
      ))}
    </Box>
  )

  const formList: FormItemProps[] = [
    {
      type: 'Input',
      name: 'firstName',
      placeholder: locale.Please_enter,
      label: locale.firstName,
      required: true,
      rules: [
        { max: 50 },
        // {
        //   pattern: /^([\u4e00-\u9fa5]+|[a-zA-Z0-9]+)$/, //TODO: 正则校验-特殊
        //   message: 'special symbols are not allowed',
        // },
      ],
      ColProps: { md: 12, xs: 24 },
    },
    {
      type: 'Input',
      name: 'lastName',
      placeholder: locale.Please_enter,
      label: locale.lastName,
      required: true,
      rules: [
        { max: 50 },
        // {
        //   pattern: /^([\u4e00-\u9fa5]+|[a-zA-Z0-9]+)$/,
        //   message: 'special symbols are not allowed',
        // },
      ],
      ColProps: { md: 12, xs: 24 },
    },
    {
      type: 'Input',
      name: 'email',
      placeholder: locale.Please_enter,
      label: locale.email,
      rules: [
        {
          pattern: /^[\w\-.]+\@+[a-z0-9]+\.[a-z]{2,4}$/,
          message: locale.please_input_correct_email,
        },
      ],
      required: true,
      ColProps: { md: 12, xs: 24 },
    },
    {
      type: 'Input',
      name: 'companyName',
      placeholder: locale.Please_enter,
      rules: [{ max: 100 }],
      label: locale.companyName,
      ColProps: { md: 12, xs: 24 },
    },
    isPc
      ? {
          type: 'Select',
          name: 'country',
          label: locale.country,
          placeholder: locale.Please_choose,
          required: true,
          ColProps: { md: 12, xs: 24 },
          data: countryList,
          optionValue: 'key',
          optionLabel: 'value',
          SelectProps: {
            onSelect: (e) => {
              const val = callCodeList?.filter((_: any) => _.countryName === e)?.[0]
                ?.countryCallingCode
              setSelectedCallCode(val)
              console.log('selectedCountry', e)
              setSelectedCountry(e)
              setSelectedServiceType('')
              form.setFieldsValue({ serviceType: null, waybillNo: '' })
            },
          },
        }
      : {
          name: 'country',
          label: locale.country,
          required: true,
          ColProps: { md: 12, xs: 24 },
          children: (
            <Box
              className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
              sx={{
                ...ptBoxInFormItemsx,
                color: form.getFieldValue('country') ? '#151741' : '#d9d9d9',
              }}
              onClick={() =>
                selectModalState.onOpen({
                  title: 'COUNTRY',
                  list: countryList,
                  value: form.getFieldValue('country'),
                  optionValue: 'key',
                  optionLabel: 'value',
                  onSelect: (e) => {
                    setSelectedServiceType('')
                    form.setFieldsValue({ serviceType: null, waybillNo: '' })
                    form.setFieldsValue({ country: e })
                    const val = callCodeList?.filter((_: any) => _.countryName === e)?.[0]
                      ?.countryCallingCode
                    setSelectedCallCode(val)
                    setSelectedCountry(e)
                  },
                })
              }
            >
              {form.getFieldValue('country')
                ? countryList?.filter((_) => _.key === form.getFieldValue('country'))?.[0]?.value
                : locale.Please_choose}
            </Box>
          ),
        },
    {
      type: 'Input',
      name: 'phoneNumber', // TODO: 正则校验--根据不同国家，号码格式及上线不同
      placeholder: locale.Please_enter,
      label: locale.phoneNumber,
      required: true,
      ColProps: { md: 12, xs: 24 },
      InputProps: { addonBefore: selectBefore },
      rules: [
        {
          pattern: new RegExp(
            `^\\d{1,${
              callCodeList?.filter((e) => e.countryCallingCode === selectedCallCode)?.[0]
                ?.phoneLength
            }}$`,
          ),
          message: locale.please_input_correct_phoneNumber,
        },
      ],
    },
    form.getFieldValue('country') === 'CHN'
      ? {
          type: 'Input',
          name: 'serviceType',
          placeholder: locale.Please_enter,
          label: locale.details,
          required: true,
          rules: [
            { max: 50 },
            // {
            //   pattern: /^([\u4e00-\u9fa5]+|[a-zA-Z0-9]+)$/,
            //   message: 'special symbols are not allowed',
            // },
          ],
          ColProps: { md: 24, xs: 24 },
        }
      : isPc
      ? {
          type: 'Select',
          name: 'serviceType',
          label: locale.whatAreYouLookingFor,
          placeholder: locale.Please_choose,
          required: true,
          ColProps: { md: 12, xs: 24 },
          data: serviceTypeList,
          optionValue: 'value',
          optionLabel: 'lable',
          SelectProps: {
            onSelect: (e) => {
              setSelectedServiceType(e)
            },
          },
        }
      : {
          name: 'serviceType',
          label: locale.whatAreYouLookingFor,
          required: true,
          Colspan: 24,
          children: (
            <Box
              className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
              sx={{
                ...ptBoxInFormItemsx,
                color: form.getFieldValue('serviceType') ? '#151741' : '#d9d9d9',
              }}
              onClick={() =>
                selectModalState.onOpen({
                  title: locale.whatAreYouLookingFor,
                  list: serviceTypeList,
                  value: form.getFieldValue('serviceType'),
                  optionValue: 'value',
                  optionLabel: 'lable',
                  onSelect: (e) => {
                    form.setFieldsValue({ serviceType: e })
                    setSelectedServiceType(e)
                  },
                })
              }
            >
              {form.getFieldValue('serviceType')
                ? serviceTypeList?.filter((_) => _.value === form.getFieldValue('serviceType'))?.[0]
                    ?.lable
                : locale.Please_choose}
            </Box>
          ),
        },
    selectedServiceType === 'customerService' && {
      type: 'Input',
      name: 'waybillNo',
      placeholder: locale.Please_enter,
      rules: [
        { max: 50 },
        {
          pattern: new RegExp(/^[0-9]*$/),
          message: locale.please_input_correct_number,
        },
      ],
      required: true,
      label: locale.please_input_waybillNo,
      // InputProps: {
      //   type: 'number',
      // },
      ColProps: { md: 12, xs: 24 },
    },
    selectedServiceType === 'RequestQuote'
      ? isPc
        ? {
            type: 'Select',
            name: 'dailyOrder',
            label: locale.dailyOrderCount,
            placeholder: locale.Please_choose,
            required: true,
            ColProps: { md: 12, xs: 24 },
            data: dailyCountList,
            optionValue: 'key',
            optionLabel: 'value',
          }
        : {
            name: 'dailyOrder',
            label: locale.dailyOrderCount,
            required: true,
            ColProps: { md: 12, xs: 24 },
            children: (
              <Box
                className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
                sx={{
                  ...ptBoxInFormItemsx,
                  color: form.getFieldValue('dailyOrder') ? '#151741' : '#d9d9d9',
                }}
                onClick={() =>
                  selectModalState.onOpen({
                    title: locale.dailyOrderCount,
                    list: dailyCountList,
                    value: form.getFieldValue('dailyOrder'),
                    optionValue: 'key',
                    optionLabel: 'value',
                    onSelect: (e) => {
                      form.setFieldsValue({ dailyOrder: e })
                    },
                  })
                }
              >
                {form.getFieldValue('dailyOrder')
                  ? dailyCountList?.filter((_) => _.key === form.getFieldValue('dailyOrder'))?.[0]
                      ?.value
                  : locale.Please_choose}
              </Box>
            ),
          }
      : null,
  ].filter(Boolean)

  //   const privacyConfig: FormItemProps = {
  //     type: 'Checkbox',
  //     name: 'isPrivacyAgreement',
  //     label: '',
  //     textColor: '#525566',
  //     options: [
  //       {
  //         label: '我已阅读并同意',
  //         value: 'true',
  //       },
  //     ],
  //     required: true,
  //     afterContent: <PrivacyAgreement pageLocale={footerLocale} />,
  //     onChange: handleCheckboxChange,
  //   }

  //   const { modalContent, onModalOpen } = usePrivacyNoticeModal({
  //     handleClick: onFinish,
  //     form,
  //     subKey: 'isPrivacyAgreement',
  //     setCheckedFlag,
  //     domRoot: 'privacyModalRoot',
  //   })

  const localCountry = Cookies.get('COUNTRY')
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false)

  const handleSubmit = () => {
    if (localCountry !== 'Italy' && localCountry !== 'Australia') {
      onFinish()
    } else {
      isPrivacyChecked ? onFinish() : message.warning(footerLocale.请阅读并同意)
    }
  }

  return (
    <div>
      <Box
        sx={{
          p: ['4px 30px', '4px 30px', '6px 52px'],
          display: 'inline-block',
          bgcolor: '#FFF303',
          borderRadius: ['19px', '19px', '26px'],
          fontSize: ['16px', '16px', '26px'],
          fontWeight: 'bold',
          cursor: 'pointer',
          '&:active': {
            opacity: '0.8',
          },
          ...btnSx,
        }}
        onClick={() => {
          onOpen()
          logEvent(btnTxt ? '点击_服务_主营业务_联系我们' : '点击_首页_咨询')
        }}
      >
        {btnTxt || locale.Enquire}
      </Box>
      {visible && (
        <FormModal
          bodyStyle={{ padding: 0 }}
          maskClosable={false}
          width={860}
          visible={visible}
          onCancel={onClose}
          onClose={onClose}
          title={locale.Enquire}
          footer={
            isPc ? (
              <>
                {localCountry === 'Brazil' && (
                  <Box
                    component={'span'}
                    sx={{
                      color: '#005EDF',
                      float: 'left',
                      lineHeight: '50px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate('/calculationQuotations')
                    }}
                  >
                    {locale.自主测算报价}
                  </Box>
                )}
                <Button key='1' onClick={onClose}>
                  {locale.CANCEL}
                </Button>
                <Button key='2' type='primary' loading={submitRes.loading} onClick={handleSubmit}>
                  {locale.SUBMIT}
                </Button>
              </>
            ) : (
              <Button
                key='3'
                type='primary'
                block
                loading={submitRes.loading}
                onClick={handleSubmit}
              >
                {locale.SUBMIT}
              </Button>
            )
          }
        >
          <Box
            sx={{
              p: ['.32rem .2rem 0 .2rem', '20px 20px 0 20px'],
              '.ant-modal-content': {
                padding: '16px 24px',
                ...(isPc
                  ? {
                      position: 'absolute',
                      width: '600px',
                      left: 'calc(50vw)',
                      top: 'calc(50vh)',
                      transform: 'translate(-50%, -50%)',
                    }
                  : {
                      borderRadius: '16px',
                      textAlign: 'center',
                    }),
              },
              '.ant-modal-body': {
                padding: '24px 0 !important',
              },
              '.ant-modal-header': {
                ...(isPc
                  ? {
                      padding: '0 0 24px 0 !important',
                    }
                  : {
                      padding: '0 !important',
                      textAlign: 'center',
                    }),
              },
              '.ant-modal-footer': {
                ...(isPc
                  ? {}
                  : {
                      display: 'flex',
                      borderTop: 0,
                      button: {
                        width: '50%',
                        borderRadius: '8px',
                      },
                    }),
              },
            }}
            id='privacyModalRoot'
          >
            <GlobalAntdFormStyle />
            <Form labelCol={{ span: 24 }} form={form} name='form_in_modal'>
              <Row gutter={20}>
                {formList.map((_) => (
                  <FormItem {..._} key={_.name} />
                ))}
              </Row>
              {localCountry === 'Italy' || localCountry === 'Australia' ? (
                <Row style={{ marginBottom: '10px' }}>
                  <Checkbox onChange={(e) => setIsPrivacyChecked(e.target.checked)} />
                  &nbsp;&nbsp;
                  {footerLocale.我已阅读并同意}&nbsp;&nbsp;
                  <PrivacyAgreement pageLocale={footerLocale} />
                </Row>
              ) : null}
            </Form>
          </Box>
          {localCountry === 'Brazil' && !isPc && (
            <Box
              sx={{
                color: '#005EDF',
                lineHeight: '50px',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={() => {
                navigate('/calculationQuotations')
              }}
            >
              {locale.自主测算报价}
            </Box>
          )}
        </FormModal>
      )}
      <SelectModalH5
        currentItem={selectModalState.currentItem}
        visible={selectModalState.visible}
        onClose={selectModalState.onClose}
      />
    </div>
  )
}

export default memo(Index)
