import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)`
  position: relative;
  .MuiSvgIcon-root {
    color: #fff;
    font-size: 2rem;
  }
  //   .MuiMobileStepper-root {
  //     position: absolute;
  //     //   top: 0;
  //     width: 100%;
  //     background: transparent;
  //     user-select: none;
  //   }

  //   .gatsby-image-wrapper {
  //     display: block;
  //     overflow: hidden;
  //      width: 100%;
  //   }

  // @media (min-width: 0px) {
  //   &,
  //   .MuiMobileStepper-root,
  //   .gatsby-image-wrapper {
  //     height: calc(70vh - 25px);
  //   }
  // }
  // @media (min-width: 600px) {
  //   &,
  //   .MuiMobileStepper-root,
  //   .gatsby-image-wrapper {
  //     height: calc(70vh - 80px);
  //   }
  // }
  // @media (min-width: 900px) {
  //   &,
  //   .MuiMobileStepper-root,
  //   .gatsby-image-wrapper {
  //     height: calc(100vh - 230px);
  //   }
  // }
`
