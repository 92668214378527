import { Box } from '@mui/material'
import React from 'react'

export const Slogan = (props) => {
  const {
    slogan,
    sloganDesc,
    fontSize1,
    fontSize2,
    btnprops, // 首页传个按钮 dom
  } = props
  return (
    <Box
      className='ptSlogan'
      sx={{
        '.maintext': {
          color: '#fff',
          fontSize: fontSize1 || ['22px', '26px', '36px', '40px', '44px'],
          fontWeight: 800,
          whiteSpace: 'pre-line',
        },
        '.subtext': {
          color: '#fff',
          fontSize: fontSize2 || ['12px', '14px', '20px', '22px', '24px'],
          whiteSpace: 'pre-line',
        },
        ...props?.sx,
      }}
    >
      <h2 className='maintext'>{slogan}</h2>
      <div className='subtext'>{sloganDesc}</div>
      {btnprops}
    </Box>
  )
}

export default Slogan
