import { logEvent } from '@/utils/logEvent'
import { Box, Input, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { useI18next } from '../../components'

const isBrowser = typeof window !== 'undefined'

type IProps = {
  callback?: () => {}
  inputVal?: string
  loading?: boolean
  locale: Record<string, any>
}

export const Track = (props: IProps) => {
  const { navigate } = useI18next()
  const theme = useTheme()
  const { direction } = theme
  const [state, setState] = useState(props.inputVal || '')
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const { locale } = props

  const handleInputChange = () => {
    logEvent('点击_首页_运单号查询')
    const isHomePage = isBrowser ? !window.location.pathname.includes('track') : false
    if (isHomePage) {
      return navigate('/track', {
        state: { inputval: state },
      })
    }
    if (!props.loading) {
      props.callback && props.callback(state)
    }
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: ['', '#095ef7'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: ['44px', '140px'],
          bottom: '105px',
          '.img': {
            width: ['39px', '66px'],
            height: ['57px', '86px'],
          },
          '.left': {
            marginInlineEnd: ['-22px', '-32px'],
            transform: direction === 'rtl' ? 'scaleX(-1)' : '',
          },
          '.right': {
            marginInlineStart: '-32px',
            mt: ' 35px',
            transform: direction === 'rtl' ? 'scaleX(-1)' : '',
          },
          width: ['343px', '100vw'],
          ...props.homebannersx,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: '#fff',
            border: '1px solid #095ef7',

            width: ['325px', '500px', '650px', '700px', '800px'],
            height: ['41px', '48px', '60px'],
            lineHeight: ['41px', '48px', '60px'],
            borderRadius: ['30px'],
            padding: '0 8px 0 20px',
            input: {
              color: '#7c7d78',
              fontSize: ['16px', '20px'],
              fontWeight: [700, 900],
              '&::placeholder': {
                color: '#7c7d78',
                fontSize: ['16px', '20px'],
                fontWeight: 500,
              },
            },
            '.MuiInput-underline': {
              '&:after, &:before': {
                display: 'none',
              },
              width: '100%',
              height: ['26px', '60px'],
            },
          }}
        >
          <Input
            onKeyDown={(e) => {
              if (e.keyCode === 13 && state) {
                handleInputChange()
              }
            }}
            onChange={(e) => setState(e.target.value)}
            value={state}
            placeholder={isPc ? locale.EnterAirWaybill : locale.EnterAirWaybill_mobile}
          />

          <Box
            sx={{
              boxSizing: 'content-box',
              px: 2,
              position: 'absolute',
              top: ['4.5px'],
              left: direction === 'rtl' ? '4px' : 'unset',
              right: direction === 'ltr' ? '4px' : 'unset',
              height: ['32px', '38px', '52px'],
              lineHeight: ['32px', '38px', '52px'],
              width: ['97px', '193px'],
              borderRadius: '26px',
              background: '#fff303',
              fontWeight: [700, 700],
              fontSize: ['14px', '16px', '26px'],
              textAlign: 'center',
              marginInlineStart: 'auto',
              mt: ['-1px', '-2px'],
              color: '#000',
              '.MuiTouchRipple-root': {
                background: '#fff303',
                color: '#000',
              },
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={(e) => {
              if (!state) {
                return
              }
              handleInputChange()
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                zIndex: 1,
                height: ['26px', '31px'],
                lineHeight: ['26px', '31px'],
                fontWeight: 600,
              }}
            >
              {locale.Track}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Track
