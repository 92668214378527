import { css } from '@emotion/react'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MobileStepper from '@mui/material/MobileStepper'
import { useTheme } from '@mui/material/styles'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { default as React, useLayoutEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { mq } from '../../utils'
import { APIqueryBizDictData, APIqueryCountry } from '../EnquireForm/api'
import EnquireFromCompotent from '../EnquireForm/index'
import Slogan from '../Slogan/bannerSlogan'
import Track from '../Track'
import { StyledBox } from './style'

// const AutoPlaySwipeableViews = SwipeableViews
const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function SwipeableTextMobileStepper({ pageLocale, footerLocale, callCodeList }) {
  const theme = useTheme()
  const { direction } = theme
  const { language } = useI18next()
  const dictRes = useRequest(() => APIqueryBizDictData('NumberofDailyOrders,WebCountry'))
  const countryList = dictRes?.data?.resultObject?.WebCountry || []
  const dailyCountList = dictRes.data?.resultObject?.NumberofDailyOrders || []

  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const [isPc, setIsPC] = useState<null | boolean>(null)
  const _isPc = useMediaQuery('@media (min-width: 800px)')

  useLayoutEffect(() => {
    setIsPC(_isPc)
  }, [_isPc])

  const BtnBox = ( // 用于 问询提交弹窗
    <div style={{ display: 'inline-block', position: 'relative', marginTop: '20px' }}>
      <EnquireFromCompotent
        locale={pageLocale}
        footerLocale={footerLocale}
        countryList={countryList}
        dailyCountList={dailyCountList}
        callCodeList={callCodeList}
      />
    </div>
  )

  const commonSx = {
    position: 'absolute',
    top: '80px',
    ...(direction === 'ltr'
      ? { right: '80px', textAlign: 'right' }
      : { left: '80px', textAlign: 'left' }),
  }
  const imagesWeb = [
    <Box
      sx={{
        height: 'calc(100vh - 90px)',
        minHeight: '700px',
        display: 'flex',
        alignItems: 'end',
        '.img': {
          height: 'calc(100vh - 90px)',
          width: ['630px', '630px', '650px'],
          minWidth: '630px',
          ml: [0, 0, '10vw', '10vw'],
          objectFit: 'contain',
          objectPosition: 'right bottom',
        },
        '.ptSlogan': {
          alignSelf: 'center',
          marginInlineStart: '80px',
        },
      }}
    >
      {/* {language !== 'ar' && (
        <img
          //   src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/banner1@2x.png'
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/banner-new.png'
          alt='iMile ECommerce Delivery Solutions'
          className='img'
        />
      )}
      {language === 'ar' && (
        <img
          //   src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/banner2@2x.png'
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/bannerleft-new.png'
          alt='iMile ECommerce Delivery Solutions'
          className='img'
        />
      )} */}

      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/newhome0912.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* {language !== 'ar' && (
        <StaticImage
          src='../../../static/images/home/banner1@2x.png'
          alt='iMile ECommerce Delivery Solutions'
          placeholder='blurred'
          objectFit='cover'
          className='img'
        />
      )}
      {language === 'ar' && (
        <StaticImage
          src='../../../static/images/home/banner2@2x.png'
          alt='iMile ECommerce Delivery Solutions'
          placeholder='blurred'
          objectFit='cover'
          className='img'
        />
      )} */}

      <Slogan slogan={pageLocale.Slogan} sloganDesc={pageLocale.SloganDesc} btnprops={BtnBox} />
    </Box>,
    <Box
      sx={{
        height: 'calc(100vh - 90px)',
        minHeight: '700px',
        display: 'flex',
        alignItems: 'end',
        '.img': {
          width: ['540px', '540px', '620px'],
          ml: [0, 0, '10vw', '10vw'],
          objectPosition: 'right bottom',
          objectFit: 'contain',
        },
        '.ptSlogan': {
          alignSelf: 'center',
          marginInlineStart: '80px',
        },
      }}
    >
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home2_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home2_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        className='img'
        objectPosition={'right bottom'}
        objectFit='contain'
      /> */}
      <Slogan slogan={pageLocale.Slogan2} sloganDesc={pageLocale.SloganDesc2} btnprops={BtnBox} />
    </Box>,
    <Box
      sx={{
        position: ['unset', 'relative'],
        height: 'calc(100vh - 90px)',
        minHeight: '700px',
        display: 'flex',
        overflow: 'hidden',
        '.img': {
          width: ['540px', '100vw'],
          top: ['', '-253px', '-253px', '-253px', '-253px'],
          height: ['', '1100px', '1100px', '1100px', '1200px'],
          left: ['', '-12vw', '-30vw', '-32vw', '-36vw'],
          objectPosition: '50% 50%',
          objectFit: 'contain',
        },
        '.ptSlogan': {
          px: '50px',
          alignSelf: 'center',
        },
      }}
    >
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home3_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home3_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        objectPosition='50% 50%'
        objectFit='contain'
        className='img'
      /> */}
      <Slogan
        slogan={pageLocale.Slogan3}
        sloganDesc={pageLocale.SloganDesc3}
        btnprops={BtnBox}
        sx={{
          position: ['unset', 'absolute'],
          top: '189px',
          right: '120px',
          textAlign: ['left', 'right'],
        }}
      />
    </Box>,
    <Box
      sx={{
        height: 'calc(100vh - 90px)',
        minHeight: '700px',
        display: 'flex',
        alignItems: 'center',
        pl: [0, 0, '60px', '80px', '110px'],
        '.img': {
          width: [0, 0, '580px', '600px', '760px'],
          objectPosition: 'right 50%',
          objectFit: 'contain',
        },
        '.ptSlogan': {
          px: '50px',
          alignSelf: 'center',
        },
      }}
    >
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home4_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home4_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        objectPosition='right 50%'
        objectFit='contain'
        className='img'
      /> */}
      <Slogan slogan={pageLocale.Slogan4} sloganDesc={pageLocale.SloganDesc4} btnprops={BtnBox} />
    </Box>,
  ]
  const imagesH5 = [
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        height: 'calc(100vh - 55px)',
        '.img': {
          height: 'calc(100vh - 55px)',
          objectPosition: 'right bottom',
          objectFit: 'contain',
        },
        '.text': { color: '#fff', height: ['286px', '220px'], pt: '10px' },
        '.maintext': {
          fontSize: '22px',
          fontWeight: 800,
          maxWidth: '270px',
          whiteSpace: 'pre-line',
        },
        '.subtext': { fontSize: '14px', maxWidth: ['318px', '540px'], whiteSpace: 'pre-line' },
      }}
    >
      {/* <img
        // src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home1_mobile_bg.png'
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/bannermiddle.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      /> */}
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/newhome0912.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home1_mobile_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        objectFit='cover'
        className='img'
      /> */}
      {/* <div className='text'>
        <div className='maintext'>{pageLocale.Slogan}</div>
        <div className='subtext'>{pageLocale.SloganDesc}</div>
      </div> */}
      <Slogan
        slogan={pageLocale.Slogan}
        sloganDesc={pageLocale.SloganDesc}
        btnprops={BtnBox}
        sx={{
          position: 'absolute',
          top: '5vh',
          // width: '100vw',
          ml: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      />
    </Box>,
    <Box
      sx={{
        height: 'calc(100vh - 55px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.img': {
          flex: 1,
          objectPosition: 'right bottom',
          objectFit: 'contain',
        },
      }}
    >
      <Slogan
        slogan={pageLocale.Slogan2}
        sloganDesc={pageLocale.SloganDesc2}
        btnprops={BtnBox}
        sx={{ mt: '70px' }}
      />
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home2_mobile_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home2_mobile_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        className='img'
        objectPosition={'right bottom'}
        objectFit='contain'
      /> */}
    </Box>,
    <Box
      sx={{
        height: 'calc(100vh - 55px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '.img': {
          // height: ['410px', '600px'],
          mt: '-91px',
          marginInlineStart: '-23px',
          objectPosition: 'left top',
        },
      }}
    >
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home3_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home3_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        objectPosition='left top'
        objectFit='cover'
        className='img'
      /> */}
      <Slogan
        slogan={pageLocale.Slogan3}
        sloganDesc={pageLocale.SloganDesc3}
        btnprops={BtnBox}
        sx={{ ml: '68px', position: 'absolute', top: '44vh', width: '100%' }}
      />
    </Box>,
    <Box
      sx={{
        height: 'calc(100vh - 55px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '.img': { width: '90vw', objectFit: 'contain' },
      }}
    >
      <img
        src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/home4_mobile_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        className='img'
      />
      {/* <StaticImage
        src='../../../static/images/home/home4_mobile_bg.png'
        alt='iMile ECommerce Delivery Solutions'
        placeholder='blurred'
        objectFit='contain'
        className='img'
      /> */}
      <Slogan
        slogan={pageLocale.Slogan4}
        sloganDesc={pageLocale.SloganDesc4}
        btnprops={BtnBox}
        sx={{ mt: '10px', marginInlineStart: '-80px', height: '245px' }}
      />
    </Box>,
  ]
  const maxSteps = imagesWeb.length

  return (
    <StyledBox
      className='banner'
      sx={{
        pt: ['55px', '55px', '90px'],
        minHeight: ['unset', 'unset', '700px'],
        bgcolor: '#095ef7',
        '.MuiMobileStepper-root': {
          zIndex: 99,
        },
      }}
    >
      <AutoPlaySwipeableViews
        axis={direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {(isPc ? imagesWeb : imagesH5).map((i, index) => (
          <Box
            key={index}
            sx={
              {
                //   filter: `brightness(1)`,
              }
            }
          >
            {i}
          </Box>
        ))}
      </AutoPlaySwipeableViews>

      <Track
        locale={pageLocale}
        homebannersx={{
          position: 'absolute',
          bottom: ['104px', 0],
          left: '50%',
          transform: 'translate(-50%)',
          bgcolor: 'unset',
        }}
      />
      <MobileStepper
        css={css`
          ${mq({
            height: '32px',
            bottom: ['73px', '100px', '50%'],
            position: 'absolute',
            width: '100%',
            background: 'transparent',
            userSelect: 'none',
            '&:hover': {
              color: '#2e8b57',
            },
          })}
        `}
        sx={{
          '.MuiMobileStepper-dots': {
            display: ['flex', 'flex', 'none'],
            justifyContent: 'center',
            width: '100%',
            '.MuiMobileStepper-dot': {
              width: '5px',
              height: '5px',
              margin: '0 5px',
              bgcolor: 'rgba(255, 255, 255, 0.4)',
            },
            '.MuiMobileStepper-dotActive': {
              width: '30px',
              borderRadius: '4px',
              bgcolor: '#fff',
            },
          },
        }}
        steps={maxSteps}
        position='bottom'
        activeStep={activeStep}
        variant='dots'
        nextButton={
          isPc && (
            <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          )
        }
        backButton={
          isPc && (
            <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
              {direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          )
        }
      />
    </StyledBox>
  )
}

export default SwipeableTextMobileStepper
