import { Box } from '@mui/material'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useTheme } from '@mui/material/styles'
import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'

interface Props {
  locales: Record<string, any>
  isPc: boolean
  country: string
}

export default ({ locales, isPc, country }: Props) => {
  const location = useLocation()
  const { language } = useI18next()
  const [lang, setLang] = useState(language)
  const theme = useTheme()
  const { direction } = theme

  useEffect(() => {
    if (country) {
      setLang(() => {
        if (country === 'CHN') {
          return 'zh'
        } else if (country === 'MEX') {
          return 'es-MX'
        }
        return 'en'
      })
    }
  }, [country])

  console.log(lang, 'langlanglang')

  const BoxWrapperSx = {
    position: 'relative',
    width: ['255px', 320, 340, 360, 400],
    height: ['285px', 340, 380, 380, 460],
    borderRadius: ['20px', '32px'],
    bgcolor: '#095ef7',
    mb: ['30px'],
    mx: '10px',
    '&:after': {
      content: '""',
      position: 'absolute',
      border: '4px solid #fff303',
      width: ['258px', 324, 344, 364, 404],
      height: ['287px', 341, 381, 381, 441],
      borderRadius: ['20px', '32px'],
      left: '-11px',
      top: '8px',
    },
    '.img': {
      position: 'absolute',
    },
  }

  const Title = (tit) => {
    return (
      <Box
        component={'h3'}
        sx={{
          position: 'absolute',
          top: ['50px', '60px'],
          textAlign: 'center',
          whiteSpace: 'pre-line',
          fontSize: ['19px', 22, 24, 26, 30],
          fontWeight: 600,
          color: '#fff',
          // m: ['35px 0 60px', '50px 0', '52px 0', '55px 0', '70px 0'],
        }}
      >
        {tit}
      </Box>
    )
  }
  const Content = (desc) => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: ['134px', '160px', '180px'],
          textAlign: 'center',
          whiteSpace: 'pre-line',
          fontSize: ['14px', 18, 20, 21, 24],
          fontWeight: 400,
          color: '#fff',
          // maxWidth: maxWidth ? maxWidth : ['187px', '300px'],
        }}
      >
        {desc}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: ['center', 'center', 'space-evenly', 'space-between'],
        maxWidth: ['var(--page-width)'],
        px: 'var(--page-px)',
        margin: '0 auto',
      }}
    >
      {/* 第一个盒子 COD */}
      {!['zh', 'es-MX'].includes(lang) && (
        <Box
          sx={{
            ...(BoxWrapperSx as any),
            pr: 2,
            '.img1': {
              left: 0,
              bottom: 0,
              width: ['54px', 66, 70, 82, 91],
            },
            '.img2': {
              right: 0,
              bottom: ['118px', '156px'],
              width: ['74px', 90, 94, 101, 111],
              zIndex: 1,
            },
          }}
        >
          <img
            src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do1_1.png'}
            className='img img1'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.WhatWeDoTit1)}
            {Content(locales.WhatWeDoCont1)}
          </Box>
        </Box>
      )}

      {/* 第二个盒子 */}
      <Box
        sx={{
          ...(BoxWrapperSx as any),
          pr: 2,
          '.img1': {
            width: ['49px', '58px'],
            bottom: [120, 142, 162, 162, 223],
            left: direction === 'ltr' ? ['14px', '18px'] : 'unset',
            right: direction === 'rtl' ? ['14px', '18px'] : 'unset',
            transform: direction === 'rtl' ? 'scaleX(-1)' : '',
          },
          '.img2': {
            left: direction === 'rtl' ? ['-44px', '-52'] : 'unset',
            right: direction === 'ltr' ? ['-98px', '-122px'] : 'unset',
            bottom: ['-31px', '-48px'],
            width: [200, 247],
            zIndex: 1,
          },
          // ml: [0, '30px'],
        }}
      >
        <img
          src='https://imile-minio-cdn.imile.com/imile-home/offical-web/home/what_we_do2_2.png'
          alt='iMile ECommerce Delivery Solutions'
          className='img img2'
        />
        {/* <StaticImage
          src='../../../static/images/home/what_we_do2_2.png'
          alt='iMile ECommerce Delivery Solutions'
          placeholder='blurred'
          objectFit='cover'
          className='img img2'
        /> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {Title(locales.WhatWeDoTit2)}
          {Content(locales.WhatWeDoCont2)}
        </Box>
      </Box>

      {/* 第3个盒子  Airway Parcel Delivery */}
      {['zh'].includes(lang) && (
        <Box
          sx={{
            ...BoxWrapperSx,
            '.img1': {
              width: ['178px', '226px'],
              top: ['-18px', '-19px'],
              bottom: 0,
              zIndex: 1,
              ...(isPc
                ? {
                    right: ['24px', '-44px'],
                  }
                : {
                    left: '-60px',
                    transform: 'scaleX(-1)',
                  }),
            },
          }}
        >
          <img src='/images/home/air.png' className='img img1' />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.WhatWeDoTit8)}
            {Content(locales.WhatWeDoCont8)}
          </Box>
        </Box>
      )}

      {/* 第三个盒子  Same Day Next Day */}
      {!['zh'].includes(lang) && (
        <Box
          sx={{
            ...BoxWrapperSx,
            '.img2': {
              right: direction === 'ltr' ? ['-53px', -82, -67, -72, -92] : 'unset',
              left: direction === 'rtl' ? ['-53px', -82, -67, -72, -92] : 'unset',
              transform: direction === 'rtl' ? 'scaleX(-1)' : '',
              bottom: ['-31px', '-42px'],
              width: [148, 200, 203, 210, 226],
              zIndex: 1,
            },
            // ml: [0, 0, '30px', '30px'],
          }}
        >
          <img
            src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do3.png'}
            className='img img2'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.WhatWeDoTit3)}
            {Content(locales.WhatWeDoCont3)}
          </Box>
        </Box>
      )}

      {/* 第四个盒子 */}
      <Box
        sx={{
          ...BoxWrapperSx,
          '.img2': {
            right: 0,
            top: ['-13px'],
            width: ['128px', 153, 157, 177, 192],
            zIndex: 1,
          },
          // ml: [0, '30px', '30px', 0]
        }}
      >
        <img
          src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do4.png'}
          className='img img2'
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {Title(locales.WhatWeDoTit4)}
          {Content(locales.WhatWeDoCont4)}
        </Box>
      </Box>

      {/* 第五个盒子 */}
      <Box
        sx={{
          ...BoxWrapperSx,
          '.img1': {
            bottom: 0,
            width: ['48px', '76px'],
            right: ['34px', '20px'],
            top: ['27px', '45px'],
          },
          '.img2': {
            right: ['-49px', -66, -103],
            bottom: ['-47px', '-48px'],
            width: ['128px', 163, 179, 179, 207],
            zIndex: 1,
          },
          // ml: [0, '30px'],
        }}
      >
        <img
          src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do5_2.png'}
          className='img img2'
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {Title(locales.WhatWeDoTit5)}
          {Content(locales.WhatWeDoCont5)}
        </Box>
      </Box>

      {/* 第六个盒子 */}
      <Box
        sx={{
          ...BoxWrapperSx,
          '.img1': {
            bottom: 0,
            width: ['168px', 209, 234, 234, 232],
            right: ['45px', '66px'],
            top: ['46px', '57px'],
          },
          '.img2': {
            right: ['30px', '42px'],
            bottom: [130, 180, 180, 180, 235],
            width: ['45px'],
            zIndex: 1,
          },
          // ml: [0, '30px'],
        }}
      >
        <img
          src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/img/what_we_do6_1.png'}
          className='img img1'
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {Title(locales.WhatWeDoTit6)}
          {Content(locales.WhatWeDoCont6)}
        </Box>
      </Box>

      {/* 第7个盒子 Document Deliveries */}
      {['zh', 'es-MX'].includes(lang) && (
        <Box
          sx={{
            ...BoxWrapperSx,
            '.img1': {
              bottom: 0,
              width: ['48px', '76px'],
              right: ['24px', '30px'],
              top: ['24px', '30px'],
            },
          }}
        >
          <img src='/images/home/DocumentDeliveries.png' className='img img1' />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Title(locales.WhatWeDoTit7)}
            {Content(locales.WhatWeDoCont7)}
          </Box>
        </Box>
      )}
    </Box>
  )
}
